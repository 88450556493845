<template>
  <ul class="footer-member">
    <li><router-link  @click="savePath($event)" :class="{now:getNowPos('jp_order'),'jp-order':1}" to="/purchasing_agent">代購服務</router-link></li>
    <li><router-link  @click="savePath($event)" :class="{now:getNowPos('order'),order:1}" to="/order">訂單/追蹤</router-link></li>
    <li><router-link  @click="savePath($event)" :class="{now:getNowPos('main'),main:1}" to="/main">主頁</router-link></li>
    <li><router-link  @click="savePath($event)" :class="{now:getNowPos('package'),package:1}" to="/package">包裹管理</router-link></li>
    <li><router-link  @click="savePath($event)" :class="{now:getNowPos('address_books'),'address-books':1}" to="/address_books">通訊錄</router-link></li>
    <li><router-link  @click="savePath($event)" :class="{now:getNowPos('forecast'),forecast:1}" to="/forecast">預報包裹</router-link></li>
    <li><router-link  @click="savePath($event)" :class="{now:getNowPos('apply_service'),'apply-service':1}" title="服務申請" to="/apply_service">服務申請</router-link></li>
    <li class="last"><a @click="showMore()" :class="{more: 1,now:getMorePos()}">展開</a></li>
  </ul>
  <ul class="more" ref="more" data-title="目錄">
    <li><router-link @click="savePath($event);showMore()" :class="{now:getNowPos('jp_order'),'jp-order':1}" to="/purchasing_agent">代購服務</router-link></li>
    <li><router-link @click="savePath($event);showMore()" :class="{now:getNowPos('order'),order:1}" to="/order">訂單/追蹤</router-link></li>
    <li><router-link @click="savePath($event);showMore()" :class="{now:getNowPos('package'),package:1}" to="/package">包裹管理</router-link></li>
    <li><router-link @click="savePath($event);showMore()" :class="{now:getNowPos('address_books'),'address-books':1}" to="/address_books">通訊錄</router-link></li>
    <li><router-link @click="savePath($event);showMore()" :class="{now:getNowPos('forecast'),forecast:1}" to="/forecast">預報包裹</router-link></li>
    <li><router-link @click="savePath($event);showMore()" :class="{now:getNowPos('apply_service'),'apply-service':1}" to="/apply_service">服務申請</router-link></li>
    <li><router-link @click="savePath($event);showMore()" :class="{now:getNowPos('main'),main:1}" to="/main">主頁</router-link></li>
  </ul>
  <a @click="showMore()"></a>
  <a class="process" href="https://planets.com.tw/process/" target="_blank">集運流程</a>
  <a class="buying" href="https://planets.com.tw/buying/" target="_blank">代購流程</a>
</template>

<script>
export default {
  name: 'member-footer',
  mounted() {
  },
  methods: {
    savePath(e) {
      let target = e.target;
      console.log(target.getAttribute('href'));
      sessionStorage.setItem('redirect_url', target.getAttribute('href'));
    },
    getNowPos(path) {
      let regs = {
        'main': /main/,
        'jp_order': /(purchasing_agent|jp_order|jp_order\/.+|kr_order|kr_order\/.+)$/,
        'kr_order': /(kr_order|kr_order\/.+)$/,
        'order': /(\/order|\/order\/\d+)$/,
        'address_books': /address_books/,
        'package': /(package|\/order\/create|to_forecast)/,
        'forecast': /\bforecast$/,
        'apply_service': /\bapply_service/
      };
      let url = this.$route.path;
      return regs[path].test(url);
    },
    getMorePos() {
      let reg = /\b(admin|user|line|currency)/;
      let url = this.$route.path;
      return reg.test(url);
    },
    showMore() {
      this.$refs.more.classList.toggle('show');
      let fb = document.getElementById('fb-root');
      (this.$refs.more.classList.contains('show')) ? fb.style.display = 'block' : fb.style.display = '';
    }
  }
}
</script>

<style lang="scss" scope>
ul.footer-member {
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  width: 100vw;
  height: 60px;
  text-align: center;
  background-color: #111111;
  > li {
    width: 20%;
    height: 60px;
    margin: 0;
    padding: 0;
    display: block;
    float: left;
    > a {
      width: 60px;
      height: 60px;
      line-height: 20px;
      display: block;
      padding: 0;
      text-decoration: none;
      color: #fff;
      font-size: 8pt;
      opacity: 0.5;
      color: transparent;
      &::before {
        content: '';
        display: block;
        width: 40px;
        height: 60px;
        margin: 0 auto;
      }
      &.main::before {
        background: transparent url("@/assets/img/home_white.png") no-repeat center center;
        background-size: 32px 32px;
      }
      &.jp-order::before {
        background: transparent url("@/assets/img/jp_order.png") no-repeat center center;
        background-size: 80px 60px;
      }
      &.order::before {
        background: transparent url("@/assets/img/order_white.png") no-repeat center center;
        background-size: 32px 32px;
      }
      &.address-books::before {
        background: transparent url("@/assets/img/books_white.png") no-repeat center center;
        background-size: 32px 32px;
      }
      &.package::before {
        background: transparent url("@/assets/img/package_white.png") no-repeat center center;
        background-size: 32px 32px;
      }
      &.forecast::before {
        background: transparent url("@/assets/img/forecast_white.png") no-repeat center center;
        background-size: 32px 32px;
      }
      &.apply-service::before {
        background: transparent url("@/assets/img/apply_service.png") no-repeat center center;
        background-size: 32px 32px;
      }
      &.more::before {
        background: transparent url("@/assets/img/more.png") no-repeat center center;
        background-size: 32px 32px;
      }
      &.now {
        opacity: 1;
      }
    }
    &:not(.last):nth-of-type(n + 5) {
      display: none;
    }
  }
  &::after {
    content: '';
    width: 100%;
    height: 0;
    clear: both;
    display: block;
    overflow: hidden;
  }
  & + ul.more {
    visibility: hidden;
    z-index: -1;
    transition: all 0.3s ease-in;
    list-style: none;
    margin: 0;
    padding: 20px 20px 60px 20px;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    &.show {
      visibility: visible;
      z-index: 10000;
      display: block;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(10px);
      opacity: 1;
      &[data-title]::before {
        content: '';
        text-align: left;
        padding: 0 16px;
        font-size: 16pt;
        font-weight: bold;
        color: #111;
        display: block;
        height: 56px;
        line-height: 56px;
      }
      & + a {
        position: fixed;
        width: 32px;
        height: 32px;
        right: 20px;
        top: 20px;
        background: transparent url("@/assets/img/close.png") no-repeat center center;
        background-size: 32px 32px;
        z-index: 10001;
      }
      & ~ a.process {
        position: fixed;
        width: 96px;
        height: 32px;
        line-height: 32px;
        left: calc(50vw - 110px);
        top: 20px;
        background-color: #fff;
        text-align: center;
        border-radius: 8px;
        text-decoration: none;
        color: #000;
        z-index: 10001;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      }
      & ~ a.buying {
        position: fixed;
        width: 96px;
        height: 32px;
        line-height: 32px;
        left: calc(50vw + 10px);
        top: 20px;
        background-color: #fff;
        text-align: center;
        border-radius: 8px;
        text-decoration: none;
        color: #000;
        z-index: 10001;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      }
    }
    > li {
      width: 50%;
      height: calc(30vh - 60px);
      margin: 0 0 20px 0;
      padding: 0;
      display: block;
      float: left;
      > a {
        width: 80%;
        height: 100%;
        line-height: 20px;
        display: block;
        padding: 10px 0;
        margin: 0 auto;
        text-decoration: none;
        color: #fff;
        font-size: 11pt;
        font-weight: bold;
        border-radius: 10px;
        &::before {
          content: '';
          display: block;
          width: 60px;
          height: calc(100% - 20px);
          margin: 0 auto;
        }
        &.main {
          display: none;
        }
        &.main::before {
          background: transparent url("@/assets/img/home_white.png") no-repeat center center;
          background-size: 60px 60px;
        }
        &.jp-order::before {
          background: transparent url("@/assets/img/jp_order.png") no-repeat center center;
          background-size: 120px 90px;
        }
        &.order::before {
          background: transparent url("@/assets/img/order_white.png") no-repeat center center;
          background-size: 60px 60px;
        }
        &.address-books::before {
          background: transparent url("@/assets/img/books_white.png") no-repeat center center;
          background-size: 60px 60px;
        }
        &.package::before {
          background: transparent url("@/assets/img/package_white.png") no-repeat center center;
          background-size: 60px 60px;
        }
        &.forecast::before {
          background: transparent url("@/assets/img/forecast_white.png") no-repeat center center;
          background-size: 60px 60px;
        }
        &.apply-service::before {
          background: transparent url("@/assets/img/apply_service.png") no-repeat center center;
          background-size: 60px 60px;
        }
        &.more::before {
          background: transparent url("@/assets/img/more.png") no-repeat center center;
          background-size: 60px 60px;
        }
        &.now {
          opacity: 1;
        }
      }
      &:nth-of-type(6n + 1) > a {
        background-color: #ffbb0c;
      }
      &:nth-of-type(6n + 2) > a {
        background-color: #f45389;
      }
      &:nth-of-type(6n + 3) > a {
        background-color: #00a789;
      }
      &:nth-of-type(6n + 4) > a {
        background-color: #d6503d;
      }
      &:nth-of-type(6n + 5) > a {
        background-color: #3d3d43;
      }
      &:nth-of-type(6n + 6) > a {
        background-color: #2450c0;
      }
    }
    &::after {
      content: '';
      width: 100%;
      height: 0;
      clear: both;
      display: block;
      overflow: hidden;
    }
  }
}
@media (max-width: 900px) and (orientation: landscape) {
  ul.footer-member {
    > li {
      width: 12.5%;
      &:not(.last):nth-of-type(n + 5) {
        display: block;
      }
      &.last {
        display: none;
      }
    }
  }
}
</style>

