<template>
  <header>
    <header-nav :user="user_rows"></header-nav>
  </header>
  <aside>
    <aside-menu :uid="user_rows.id"></aside-menu>
  </aside>
  <main ref="main">
    <router-view :user="user_rows" :warehouses="warehouses" v-slot="{ Component }" @changeUser="changeUser">
      <transition :name="$route.meta.transition" @transitionstart="start()" @transitionend="end()">
      <component :is="Component" />
      </transition>
    </router-view>
  </main>
  <footer>
    <member-footer></member-footer>
  </footer>
  <!-- built files will be auto injected -->
  <!-- Messenger 洽談外掛程式 Code -->
  <div id="fb-root"></div>
  <!-- Your 洽談外掛程式 code -->
  <div id="fb-customer-chat" class="fb-customerchat">
  </div>

</template>

<script>
import axios from 'axios';
// @ is an alias to /src
import HeaderNav from '@/components/HeaderNav.vue';
import AsideMenu from '@/components/AsideMenu.vue';
import MemberFooter from '@/components/MemberFooter.vue';

export default {
  name: 'HomeView',
  components: {
    HeaderNav,
    AsideMenu,
    MemberFooter,
  },
  props: ['user_rows'],
  data() {
    return {
      warehouses: []
    };
  },
  mounted() {
    this.checkUserRows();
    this.message();
    this.getWarehouse();
  },
  methods: {
    getWarehouse() {
      let config = {
        method: 'get',
        url: `/api/warehouse`
      };
      axios(config).then((json) => {
        return json.data;
      }).then((data) => {
        this.warehouses = data.list;
      }).catch((e) => {
        console.log(e);
      });
    },
    checkUserRows() {
      if (Object.keys(this.$props.user_rows).length == 0) {
        this.$router.push('/login');
      } else {
        let redirect_url = sessionStorage.getItem('redirect_url');
        sessionStorage.removeItem('redirect_url');
        if (redirect_url) {
          this.$router.push(redirect_url);
        } else {
          this.$router.push('/main');
        }
      }
    },
    message() {
      var chatbox = document.getElementById('fb-customer-chat');
      chatbox.setAttribute("page_id", "115625591474566");
      chatbox.setAttribute("attribution", "biz_inbox");

      window.fbAsyncInit = function() {
        window.FB.init({
          xfbml            : true,
          version          : 'v17.0'
        });
      };
      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/zh_TW/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    },
    start() {
      //this.$refs.main.style.height = `calc(100vh - 70px)`;
      //this.$refs.main.style.overflow = `hidden`;
    },
    end() {
      //this.$refs.main.style.height = ``;
      //this.$refs.main.style.overflow = ``;
    },
    changeUser(val) {
      this.$emit('updateStatus', val);
    }

  }
}
</script>
<style>
footer {
  display: none;
}
#fb-root {
  display: none;
}
@media (max-width: 500px) and (orientation: portrait) {
  footer {
    display: block;
    width: 100vw;
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
  }
}
@media (max-width: 900px) and (orientation: landscape) {
  footer {
    display: block;
    width: 100vw;
    height: 60px;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
</style>
