<template>
  <div class="outer-box">
    <div class="picture">
    </div>
    <div class="inner-box">
      <div class="login-box">
        <template v-if="show_email == ''">
          <div class="logo-box">
            <h3>後端系統</h3>
          </div>
          <div class="login">
            <form name="login_form" method="post" action="/api/admin_login" @submit.prevent="toLogin()" ref="login_form" >
              <input type="hidden" name="key" ref="key" />
              <div>
                <label>帳號</label>
                <div>
                  <input type="text" name="username"  placeholder="後端帳號"/>
                </div>
              </div>
              <div>
                <label class="password">密碼</label>
                <div>
                  <input type="password" name="password" placeholder="後端密碼" />
                </div>
              </div>
              <div>
                <button>登入</button>
              </div>
            </form>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'admin-view',
  data() {
    return {
      admin: {},
      show_email: '',
    }
  },
  components: [
  ],
  mounted() {
    this.checkUserRows();
  },
  watch: {
    admin: function (newVal, oldVal) {
      if (Object.keys(newVal).length && Object.keys(oldVal).length == 0) {
        this.$router.push('/nimda/home');
      }
    }
  },
  methods: {
    checkUserRows() {
      let token = localStorage.getItem('api_token');
      let config = {
        method: 'get',
        url: '/api/admin_row',
        headers: {
          'Authentication': token
        }
      };
      axios(config).then((json) => {
        return json.data;
      }).then((data) => {
        this.admin = data;
      }).catch((e) => {
        console.log(e);
      });

    },
    toLogin() {
      let post_data = new FormData(this.$refs.login_form);
      let config = {
        method: 'post',
        url: this.$refs.login_form.getAttribute('action'),
        data: post_data 
      };
      axios(config).then((json) => {
        return json.data;
      }).then((data) => {
        if (data.result == 1) { 
          localStorage.setItem('api_token', data.token);
          this.$router.push('/nimda/home');
        } else if (data.err) {
          alert(data.err);
        }
      }).catch((e) => {
        console.log(e);
      });
    }
  }
}
</script>

